<template>
	<div class="py-16 overflow-hidden" v-if="state.loaded && state.entries.length > 0">
		<div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
			<div class="text-base max-w-prose mx-auto lg:max-w-none">
				<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-azul sm:text-4xl text-center">Artículos del Blog</p>
			</div>

			<div class="sm:grid sm:grid-cols-2 md:grid-cols-4 gap-6">
				<div v-for="item in state.entries" :key="item.id">
					<div class="hover:shadow-md rounded-md">
						<router-link :to="'/blog/' + item.slug" class="rounded-md hover:shadow-md">
							<img :src="item.image" alt="" class="object-cover h-48 w-full rounded-t-md">
							<div class="p-4 pt-3 h-16 border border-gray-200 border-t-0 flex items-center rounded-b-md  bg-gradient-to-b from-white via-white to-gray-100">
								<div class="text-base text-vino font-medium line-clamp-2 leading-tight">{{item.title}}</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'
import { onMounted, reactive } from 'vue';

let contentful = require('contentful')
const client = contentful.createClient({
    space: 'ngc1yxicbwza',
    accessToken: 'sqPUI45ZMWM2WEEIOfgPtAaXQwclfTIsLoLQnPgvS08'
});

export default {
	components: {
		Menu
	},
	setup() {
		const state = reactive({
			loaded: false,
			entries: []
		});

		onMounted(() => {
			getEntries();
		});

		function getEntries() {
			client.getEntries({
				limit: 1000,
				content_type: 'entryBlog'
			}).then(response => {
				response.items.forEach(dt => {
					let obj = {
						id: dt.sys.id, 
						title: dt.fields.title, 
						slug: dt.fields.slug, 
						image: dt.fields.image.fields.file.url,
						content: dt.fields.content
					};

					// let tempo = new Date(dt.fields.date);
					// obj.date = tempo.toLocaleString('es-MX', {year: 'numeric', month: 'long', day: 'numeric'});

					state.entries.push(obj);
				});

				state.loaded = true;
			});
		}

		return {
			state
		}
	}
}
</script>

