<template>
    <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <h1>
                    <span class="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                        APRENDO <StarIcon class="text-verde inline-block m-4 h-4" /> ME QUIERO <StarIcon class="text-verde inline-block m-4 h-4" /> ME CUIDO
                    </span>
                    <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                        <span class="text-azul-light">Programa digital de</span> <span class="text-azul">educación sexual infantil</span>
                        <span class="text-morado ml-3">y prevención de abuso.</span>
                    </span>
                </h1>
                <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Apoyamos con libros y juegos interactivos a padres, escuelas y maestros a cumplir con su responsabilidad de educar sexualmente a niñas y niños, para que aprendan en forma ágil, divertida y relajada, propiciando una visión natural y positiva hacia la sexualidad.
                </p>
                <div class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 space-y-2">
                    <div class="text-base text-gray-500 flex items-center space-x-2">
                        <DocumentTextIcon class="w-5 h-5 text-cyan" />
                        <router-link to="/escuelas" class="hover:underline cursor-pointer hover:text-cyan-dark">Para escuelas</router-link>
                        <ArrowNarrowRightIcon class="w-6 h-6 text-cyan" />
                    </div>
                    <div class="text-base text-gray-500 flex items-center space-x-2">
                        <DocumentTextIcon class="w-5 h-5 text-amarillo-dark" />
                        <router-link to="/padres-de-familia" class="hover:underline cursor-pointer hover:text-amarillo-dark">Para padres de familia</router-link>
                        <ArrowNarrowRightIcon class="w-6 h-6 text-amarillo-dark" />
                    </div>
                </div>
            </div>

            <!-- VIDEO -->
            <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <svg class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784" aria-hidden="true">
                    <defs>
                        <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor" />
                    <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
                </svg>
                <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                    <button type="button" class="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azul-light" @click="state.showVideo=true">
                        <span class="sr-only">Ve nuestro video</span>
                        <img class="w-full" :src="require('@/assets/home/video-screen.png')" alt="" />
                        <div class="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                            <svg class="h-20 w-20 text-verde" fill="currentColor" viewBox="0 0 84 84">
                                <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                                <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </main>

    <ModalVideo v-if="state.showVideo" @close="state.showVideo=false" />
</template>

<script>
import { ArrowNarrowRightIcon, DocumentTextIcon } from '@heroicons/vue/outline'
import { StarIcon } from '@heroicons/vue/solid'
import ModalVideo from '@/components/home/ModalVideo'
import { reactive } from 'vue'

export default {
	components: {
		ArrowNarrowRightIcon,
		DocumentTextIcon,
        StarIcon,
        ModalVideo
	},
    setup() {
        const state = reactive({
            showVideo: false
        });

        return {
            state
        }
    }
}
</script>